import { BModal } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import planInputsObject from '@components/modals/plan/planInputsObject';
import planFormValidations from '@components/modals/plan/planFormValidations';
import PlanBilling from './sections/planBilling'
import PlanConfiguration from './sections/planConfiguration'
import PlanInformation from './sections/planInformation'
import PlanInstallments from './sections/planInstallments'
import PlanModifiers from './sections/planModifiers'
import { objectProp } from './commons/propType';

import {
  create as createPlanService,
  update as updatePlanService,
} from '@graphql/plan/services';

export default {
  name: 'planModal',
  mixins: [validationMixin],
  data() {
    return {
      submitClick: false,
      formData: {}
    };
  },
  props: {
    modalData: objectProp,
  },
  validations: {
    ...planFormValidations
  },
  computed: {
    formMode() {
      return this.modalData.id
        ? 'update'
        : 'create';
    },
    modalTitle() {
      return this.$t(`planModal.modalTitle.${this.formMode}`);
    },
    modalButton() {
      return this.$t(`planModal.modalButton.${this.formMode}`);
    },
    readonly() {
      return this.formMode === 'update'
    },
    childrenProps() {
      return {
        props: {
          formData: (this.formMode === 'create') ? this.formData : this.modalData,
          formMode: this.formMode,
          readonly: this.readonly,
          submitClick: this.submitClick,
          validator: this.$v,
        },
        on: {
          change: this.inputHandler,
        },
      }
    },
  },
  beforeMount() {
    this.setFormData()
  },
  methods: {
    setFormData() {
      this.formData = (this.formMode === 'create')
        ? { ...planInputsObject }
        : this.modalData;
    },
    inputHandler({ name, data }) {
      this.formData[name] = data;
      this.$v.formData[name]?.$touch();
    },
    hideHandler() {
      this.$emit('hidden');
      this.setFormData();
    },
    async submitHandler(evt) {
      evt.preventDefault();
      this.submitClick = true;

      try {
        if (this.formMode === 'create') {
          this.$v.$touch();
          if (this.$v.$invalid) return;

          await createPlanService({
            ...this.formData,
            purchaseOptionPeriod: parseInt(this.formData['purchaseOptionPeriod']),
            preTransactionInstallmentsCycle: parseInt(this.formData['preTransactionInstallmentsCycle'])
          });
        } else if (this.formMode === 'update') {
          this.$v.validationGroup.$touch();
          if (this.$v.validationGroup.$invalid) return;

          await updatePlanService(this.formData);
        }

        await this.$swal({
          type: 'success',
          title: this.$t(`planModal.alerts.success.${this.formMode}`),
          text: '',
        });

        this.$emit('success');
        this.$bvModal.hide('plan-modal');
      } catch (e) {
        await this.$swal({
          type: 'error',
          title: this.$t(`planModal.alerts.error.${this.formMode}`),
          text: e,
        });
      }
    },
  },
  render(h) {
    return h(
      BModal,
      {
        attrs: {
          id: 'plan-modal',
        },
        props: {
          okTitle: this.modalButton,
          okOnly: this.readonly,
          title: this.modalTitle,
          size: 'xl',
        },
        on: {
          hidden: this.hideHandler,
          cancel: this.hideHandler,
          ok: this.submitHandler,
          shown: this.setFormData,
        }
      },
      [
        h(
          PlanInformation,
          { ...this.childrenProps },
        ),
        h(
          PlanConfiguration,
          { ...this.childrenProps },
        ),
        h(
          PlanBilling,
          { ...this.childrenProps },
        ),
        h(
          PlanInstallments,
          { ...this.childrenProps },
        ),
        h(
          PlanModifiers,
          { ...this.childrenProps },
        ),
      ]
    )
  },
}
