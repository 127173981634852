export default {
  name: null,
  transactionCycle: null,
  minimumPeriodContract: null,
  purchaseOptionPeriod: null,
  pre_transaction_installments_cycle: null,
  status: null,
  //---
  description: null,
  category: null,
  isZeroKm: false,
  billingType: null,
  product_type: null,
  autoService: false,
  //---
  kindConfigurations: {
    "FINE": {
      "amount": 0,
      "maxDays": 90,
      "installments": 6
    },
    "NIC": {
      "amount": 0,
      "maxDays": 90,
      "installments": 6
    },
    "THEFT": {
      "amount": 0,
      "maxDays": 90,
      "installments": 6
    },
    "OTHERS": {
      "amount": 0,
      "maxDays": 90,
      "installments": 6
    },
    "SEIZED": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "CAR_KEY": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "RECOVER": {
      "amount": 0,
      "maxDays": 90,
      "installments": 3
    },
    "NO_SHOW": {
      "amount": 0,
      "maxDays": 50,
      "installments": 3
    },
    "CAR_WASH": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "RECURRENCY": {
      "amount": 0,
      "maxDays": 90,
      "installments": 1
    },
    "MAINTENANCE": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "PRE_PAYMENT": {
      "amount": 0,
      "maxDays": 90,
      "installments": 3,
      "installment_amount": 0,
      "pix_installments": 1
    },
    "PRE_PAYMENT_RTO": {
      "amount": 0,
      "maxDays": 150,
      "installments": 5
    },
    "PRE_PAYMENT_INSTALLMENT": {
      "amount": 0,
      "maxDays": 61,
      "installments": 3
    },
    "PRE_PAYMENT_PROMOTIONAL": {
      "amount": 0,
      "maxDays": 90,
      "installments": 3
    },
    "PRE_PAYMENT_RESERVE": {
      "amount": 0,
      "maxDays": 90,
      "installments": 3
    },
    "DESMOB_PRE_PAYMENT": {
      "amount": 0,
      "maxDays": 90,
      "installments": 3
    },
    "FIRST_PAYMENT": {
      "amount": 0,
      "maxDays": 90,
      "installments": 1
    },
    "FIRST_PAYMENT_EXCHANGE": {
      "amount": 0,
      "maxDays": 90,
      "installments": 1
    },
    "BAD_USE_ACTIVE": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "FULL_COLLECTION": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "MISAPPROPIATION": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "BAD_USE_INACTIVE": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "LICENCE_PLATE_ITEMS": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "DELAY_PREVENTIVE": {
      "amount": 0,
      "maxDays": 50,
      "installments": 3
    },
    "SUBSCRIPTION_TERMINATION": {
      "amount": 0,
      "maxDays": 90,
      "installments": 6
    },
    "THEFT_COPARTICIPATION_MAXIMUM": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "THEFT_COPARTICIPATION_MINIMUN": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "COLLISION_COPARTICIPATION_KOVI": {
      "amount": 0,
      "maxDays": 50,
      "installments": 6
    },
    "DEPOSIT": {
      "amount": 0,
      "maxDays": 7,
      "installments": 1
    }
  },
  modifiers: {
    'PAY_PER_MILE': {
      ranges: [
        // {
        //   start: 0,
        //   end: 100,
        //   value: '',
        // }
        // {
        //   start: 101,
        //   end: 500,
        //   value: '',
        // },
        // {
        //   start: 501,
        //   end: 850,
        //   value: '',
        // },
        // {
        //   start: 851,
        //   end: 1250,
        //   value: '',
        // },
        // {
        //   start: 1250,
        //   end: 0,
        //   value: '',
        // },
      ]
    }
  },
}

export const modifiers = {
  'PAY_PER_MILE': {
    ranges: [
      // {
      //   start: 0,
      //   end: 100,
      //   value: '',
      // },
      // {
      //   start: 101,
      //   end: 500,
      //   value: '',
      // },
      // {
      //   start: 501,
      //   end: 850,
      //   value: '',
      // },
      // {
      //   start: 851,
      //   end: 1250,
      //   value: '',
      // },
      // {
      //   start: 1250,
      //   end: 0,
      //   value: '',
      // },
    ]
  }
}

export const kindConfigurations = {
  "FINE": {
    "amount": 0,
    "maxDays": 90,
    "installments": 6
  },
  "NIC": {
    "amount": 0,
    "maxDays": 90,
    "installments": 6
  },
  "THEFT": {
    "amount": 0,
    "maxDays": 90,
    "installments": 6
  },
  "OTHERS": {
    "amount": 0,
    "maxDays": 90,
    "installments": 6
  },
  "SEIZED": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "CAR_KEY": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "RECOVER": {
    "amount": 0,
    "maxDays": 90,
    "installments": 3
  },
  "NO_SHOW": {
    "amount": 0,
    "maxDays": 50,
    "installments": 3
  },
  "CAR_WASH": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "RECURRENCY": {
    "amount": 0,
    "maxDays": 90,
    "installments": 1
  },
  "MAINTENANCE": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "PRE_PAYMENT": {
    "amount": 0,
    "maxDays": 90,
    "installments": 3
  },
  "PRE_PAYMENT_RTO": {
    "amount": 0,
    "maxDays": 150,
    "installments": 5
  },
  "PRE_PAYMENT_INSTALLMENT": {
    "amount": 0,
    "maxDays": 61,
    "installments": 3
  },
  "DESMOB_PRE_PAYMENT": {
    "amount": 0,
    "maxDays": 90,
    "installments": 3
  },
  "PRE_PAYMENT_PROMOTIONAL": {
    "amount": 0,
    "maxDays": 90,
    "installments": 3
  },
  "PRE_PAYMENT_RESERVE": {
    "amount": 0,
    "maxDays": 90,
    "installments": 3
  },
  "FIRST_PAYMENT": {
    "amount": 0,
    "maxDays": 90,
    "installments": 1
  },
  "FIRST_PAYMENT_EXCHANGE": {
    "amount": 0,
    "maxDays": 90,
    "installments": 1
  },
  "BAD_USE_ACTIVE": {
    "amount": 0,
    "maxDays": 65,
    "installments": 6
  },
  "FULL_COLLECTION": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "MISAPPROPIATION": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "BAD_USE_INACTIVE": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "LICENCE_PLATE_ITEMS": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "DELAY_PREVENTIVE": {
    "amount": 0,
    "maxDays": 50,
    "installments": 3
  },
  "SUBSCRIPTION_TERMINATION": {
    "amount": 0,
    "maxDays": 90,
    "installments": 6
  },
  "THEFT_COPARTICIPATION_MAXIMUM": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "THEFT_COPARTICIPATION_MINIMUN": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "COLLISION_COPARTICIPATION_KOVI": {
    "amount": 0,
    "maxDays": 50,
    "installments": 6
  },
  "DEPOSIT": {
    "amount": 0,
    "maxDays": 7,
    "installments": 1
  }
}
